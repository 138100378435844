
const HumanCentredDesign = () => {
    return(
        <div className="Folio-outter">
            <div><h1 className="folioTitle">GoodTime<span className="seconfont">/HCD #1</span></h1></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default HumanCentredDesign;