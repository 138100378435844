
const ToolsDesign = () => {
    return(
        <div className="Folio-outter">
            <div><h1 className="folioTitle">GoodTime<span className="seconfont">/Tools</span></h1></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default ToolsDesign;